import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NewYorkEconomicImpactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cover2003Img: file(relativePath: { eq: "economic-impact/NY2003.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cover2010Img: file(relativePath: { eq: "economic-impact/NY2010.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inProgressImg: file(
        relativePath: { eq: "economic-impact/tbdCover.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="New York Economic Impact" />
      <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
          <div className="swiper-wrapper">
            <BackgroundImage
              Tag="section"
              className="swiper-slide slide-center post-hero"
              fluid={data.topImage.childImageSharp.fluid}
            >
              <div className="slide-content row text-center">
                <div className="col-12 mx-auto inner">
                  <nav
                    data-aos="zoom-out-up"
                    data-aos-delay="800"
                    aria-label="breadcrumb"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/economic-impact">Economic Impact</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        New York State
                      </li>
                    </ol>
                  </nav>
                  <h1 className="mb-0 text-white effect-static-text">
                    New York State
                  </h1>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </div>
      </section>
      <section className="section-1 process offers">
        <div className="container pb-5">
          <div className="row intro">
            <div className="col-lg-8">
              <span className="pre-title m-0">Statewide Economic Impact</span>
              <h2>
                New York{" "}
                <span className="featured">
                  <span>Airports</span>
                </span>
              </h2>

              <p className="text-max-800">
                R.A. Wiedemann &amp; Associates was selected to serve as the
                project manager of a consulting team evaluating economic impacts
                of aviation in New York State in 2003.
              </p>

              <blockquote>
                This involved the estimation of IMPLAN impacts for 76 airports,
                including all 18 airline service facilities in the State.
              </blockquote>

              <p>
                {" "}
                Significant coordination with the Port Authority of New York and
                New Jersey was handled by R.A. Wiedemann &amp; Associates in
                developing updated impacts for JFK International and La Guardia.
                With several updates to the study, decision makers can track
                growth of aviation economic activity and publicize the very
                large contribution of jobs to the overall economy.
              </p>
            </div>
            <div className="col-lg-4 mt-5">
              <Img
                className="d-none d-lg-block report-shaddow"
                fluid={data.cover2010Img.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
        <div className="container full">
          <div className="row justify-content-center text-center items">
            <div className="col-6 col-md-4 col-lg-3 item">
              <div className="step">
                <span>2003</span>
              </div>
              <div className="px-lg-5  px-md-5  px-sm-3  pt-3">
                <Img
                  className="report-shaddow"
                  fluid={data.cover2003Img.childImageSharp.fluid}
                />
              </div>
              <p className="mb-0">Total Statewide Aviation Economic Impact:</p>
              <h5 className="mt-1">$35.4 billion</h5>
            </div>
            <div className="col-6 col-md-4 col-lg-3 item">
              <div className="step">
                <span>2010</span>
              </div>
              <div className="px-lg-5  px-md-5  px-sm-3  pt-3">
                <Img
                  className="report-shaddow"
                  fluid={data.cover2010Img.childImageSharp.fluid}
                />
              </div>
              <p className="mb-0">Total Statewide Aviation Economic Impact:</p>
              <h5 className="mt-1">$50.3 billion</h5>
            </div>
            <div className="col-6 col-md-4 col-lg-3 item">
              <div className="step">
                <span>2021</span>
              </div>
              <div className="px-lg-5  px-md-5  px-sm-3  pt-3">
                <Img
                  className="report-shaddow"
                  fluid={data.inProgressImg.childImageSharp.fluid}
                />
              </div>
              <p className="mb-0">Total Statewide Aviation Economic Impact:</p>
              <h5 className="mt-1">TBD</h5>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NewYorkEconomicImpactPage
